import React, { useState, useEffect } from 'react';
import './BrandingLogo.css';
import { Link } from 'react-router-dom';

function BrandingLogo() {
    return (
        <Link to={{
            pathname: '/'
        }} >
            <div className="BrandingLogo-container">
                <div className="BrandingLogo-img">
                    <img src="logo192.png"></img>
                </div>
                <div className='BrandingLogo-text'>
                    <h1 className='BrandingLogo'>Campsite Scout</h1>
                </div>
            </div>
        </Link>
    )
}

export default BrandingLogo