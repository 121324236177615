import React, { useState } from 'react';
// import logo from './logo.svg';
import './App.css';
import HomePage from '../HomePage/HomePage';
import FacilitiesPage from '../FacilitiesPage/FacilitiesPage';
import FacilityPage from '../FacilityPage/FacilityPage';
import AboutPage from '../AboutPage/AboutPage';
import ExplorePage from '../ExplorePage/ExplorePage';
import ContactPage from '../ContactPage/ContactPage';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import FacilitiesMap from '../FacilitiesMap/FacilitiesMap'
import ReactGA from 'react-ga4'

function App() {
  // Google Analytics
  const TRACKING_ID = "G-22V45SJCMB";
  ReactGA.initialize(TRACKING_ID);

  // Master list of query params used as hooks in various components
    // Query params to store state 
    // Option: match gives us props from the URL to prepopulate our state
    // Option: read in props from match.params (our URL props) to populate our inputs. See https://youtu.be/Law7wfdg_ls?t=1800
    // Note: Tried to use useQueryParams but couldn't specify the param name for URL
  // const [campground, setCampground] = useQueryParam('id', StringParam); // This is used only for FacilityPage
  // const [placeLabel, setPlaceLabel] = useQueryParam('place', StringParam);
  // const [latitude, setLatitude] = useQueryParam('latitude', NumberParam);
  // const [longitude, setLongitude] = useQueryParam('longitude', NumberParam);
  // const [userDefinedDateRanges, setUserDefinedDateRanges] = useQueryParam('dates', DelimitedArrayParam);
  // const [consecutiveDays, setConsecutiveDays] = useQueryParam('nights', NumberParam);
  // const [weekendDays, setWeekendDays] = useQueryParam('weekenddays', NumberParam);
  // const [monthsOfYear, setMonthsOfYear] = useQueryParam('monthsofyear', DelimitedArrayParam);
  // const [nextMonths, setNextMonths] = useQueryParam('nextmonths', NumberParam);
  // const [equipment, setEquipment] = useQueryParam('equipment', DelimitedArrayParam);
  // const [experience, setExperience] = useQueryParam('experience', StringParam); // This might change to "activities" 
  
  // Resource: React router: https://www.youtube.com/watch?v=Law7wfdg_ls
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <div className="App">
          {/* <Nav /> */}
          <Switch>
            {/* <Route exact path="/camp-scout">
                <Redirect to="/" />
              </Route> */}
            <Route path="/" exact>
              <HomePage/>
            </Route>
            <Route path="/search">
              <FacilitiesPage/>
            </Route>
            {/* <Route path="/campground">
              <FacilityPage />
            </Route> */}
            {/* <Route path="/explore">
              <ExplorePage />
            </Route> */}
            {/* <Route path="/about">
              <AboutPage />
            </Route> */}
            {/* <Route path="/contact">
              <ContactPage />
            </Route> */}
            {/* <Route path="/maptest">
              <FacilitiesMap />
            </Route> */}
          </Switch>
        </div>
      </QueryParamProvider>
    </Router>
  );
}

export default App;
